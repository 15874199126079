import Vue from 'vue'

Vue.filter('nairaFormatter', (amount) => {
  const format = new Intl.NumberFormat('en-NG',
    { style: 'currency', currency: 'NGN' }
  ).format(amount)

  return format
})

Vue.filter('tableLen30', (str) => {

  const len = str.length

  if (len <= 35) {
    return str
  } else {
    return str.substring(0,30) + ' ...'
  }
})