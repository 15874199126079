import Vue from 'vue'
import Router from 'vue-router'
import httpServ from '../services/HTTPService'

Vue.use(Router)

export const routes = [{
  path: '/',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'signin',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/SignIn.vue')
  }]
},{
  path: '/dashboard',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/Dashboard.vue')
  }]
},{
  path: '/companyProfile',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'companyProfile',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/CompanyProfile.vue')
  }]
},
{
  path: '/assets_products',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'assets_products',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/AssetsAndProducts.vue')
  }]
},{
  path: '/warehouses',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'warehouses',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/Warehouses.vue')
  }]
},{
  path: '/products',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'products',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/ProductsPage.vue')
  }]
},{
  path: '/packages',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'packages',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/PackagesPage.vue')
  }]
},{
  path: '/ledger',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'ledger',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/Ledger.vue')
  }]
}
,{
  path: '/remitaInvoice',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'remitaInvoice',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/RemitaInvoice.vue')
  }]
},{
  path: '/remitaResponse',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'remitaResponse',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/RemitaResponse.vue')
  }]
},{
  path: '/representatives',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'representatives',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/Representatives.vue')
  }]
},
{
  path: '/RRRtransactionhistory',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'RRRtransactionhistory',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/RRRTransactionHistory.vue')
  }]
},{
  path: '/legacyTransactionhistory',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'Legacytransactionhistory',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/LegacyTransactionHistory.vue')
  }]
},
{
  path: '/settings',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'settings',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/Settings.vue')
  }]
},{
  path: '/notification',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'notification',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/Notification.vue')
  }]
},{
  path: '/quaterlyReportFiling',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'quaterlyReportFiling',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/QuaterlyReportFiling.vue')
  }]
},{
  path: '/purchaseReportFiling',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'purchaseReportFiling',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/PurchaseReportFiling.vue')
  }]
},{
  path: '/importationReportFiling',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'importationReportFiling',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/ImportationReportFiling.vue')
  }]
},{
  path: '/salesReportFiling',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'salesReportFiling',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/SalesReportFiling.vue')
  }]
},
{
  path: '/logout',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'logout'
  }]
},
{
  path: '/dummyDashboard',
  component: () => import(/* webpackChunkName: "layout-default" */ '@/layouts/DefaultLayout.vue'),
  children: [{
    path: '',
    name: 'dummyDashboard',
    component: () => import(/* webpackChunkName: "starter-page" */ '@/pages/DummyDashboard.vue')
  }]
},
{
  path: '*',
  component: () => import(/* webpackChunkName: "layout-error" */ '@/layouts/ErrorLayout.vue'),
  children: [{
    path: '',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '@/pages/error/NotFoundPage.vue')
  }]
}]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

/**
 * Before each route update
 */
/*router.beforeEach((to, from, next) => {
  return next()
})*/
router.beforeEach(async (to,from,next) => {
  const data = await httpServ.getSessionJson('memberData')
  const haveAccess = data !== null ? true : false
  const publicPages = ['signin','logout']
  const authRequired = !publicPages.includes(to.name)

  if (authRequired) {
    if (!haveAccess) {
      return next('/')
    }

    return next()
  } else if (to.name === 'logout') {
    httpServ.clearSession()
    window.location.href = 'https://nfmp.gov.ng'

    return false
  } else {
    return next()
  }

  //return false
})

/*router.beforeEach((to, from, next) => {
  const memberData = httpServ.getSessionJson('memberData')

  if (to.name === 'signin' || to.name === 'dummyDashboard') {
    if (memberData !== null) {
      next()

      return
    }
    next('/signin')

    return
  } else {
    next()

    return
  }
})*/

/**
 * After each route update
 */
router.afterEach((to, from) => {
})

export default router
